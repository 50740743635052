/**
 * Wait for the given milliseconds
 */
function waitFor<T>(milliseconds: number): Promise<T> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Execute a promise and retry with exponential backoff
 * based on the maximum retry attempts it can perform
 */
export function retry<P extends any[], T>(
  promise: (...args: P) => Promise<T>,
  maxRetries: number,
  onRetry?: (attempt: number, waitTime: number) => void,
): (...args: P) => Promise<T> {
  // Notice that we declare an inner function here
  // so we can encapsulate the retries and don't expose
  // it to the caller. This is also a recursive function
  async function retryWithBackoff(retries: number, ...args: P): Promise<T> {
    // Here is where the magic happens.
    // on every retry, we increase the time to wait exponentially.
    // Here is how it looks for a `maxRetries` = 3
    // (2 ** 1) * 200 = 400 ms
    // (2 ** 2) * 200 = 800 ms
    // (2 ** 3) * 200 = 1600 ms
    const timeToWait = 2 ** retries * 200;
    try {
      // Make sure we don't wait on the first attempt
      if (retries > 0) {
        console.log(`retry: waiting for ${timeToWait}ms...`);
        await waitFor(timeToWait);
      }
      const result = await promise(...args);
      if (result && (result as any).error != null) {
        console.log("retry: error in result");
        if (retries < maxRetries) {
          onRetry?.(retries + 1, timeToWait);
          return retryWithBackoff(retries + 1, ...args);
        } else {
          console.warn("retry: max retries reached. Bubbling the error up");
          return result;
        }
      }
      return result;
    } catch (e) {
      // only retry if we didn't reach the limit
      // otherwise, let the caller handle the error
      console.log("retry: thrown an error");
      if (retries < maxRetries) {
        onRetry?.(retries + 1, timeToWait);
        return retryWithBackoff(retries + 1, ...args);
      } else {
        console.warn("retry: max retries reached. Bubbling the error up");
        throw e;
      }
    }
  }

  return (...args: P) => retryWithBackoff(0, ...args);
}
